import React from 'react';
import {
  Box,
  Paper,
  Typography,
  Slider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Button,
  Chip,
  Grid,
} from '@mui/material';
import { FilterList as FilterIcon } from '@mui/icons-material';

export default function ListingFilters({ filters, onFilterChange, onReset }) {
  const handleChange = (name, value) => {
    onFilterChange({ ...filters, [name]: value });
  };

  const handlePriceRangeChange = (event, newValue) => {
    onFilterChange({
      ...filters,
      minPrice: newValue[0],
      maxPrice: newValue[1],
    });
  };

  return (
    <Paper elevation={1} sx={{ p: 3, mb: 3 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Typography variant="h6" component="h2" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <FilterIcon /> Filters
        </Typography>
        <Button variant="text" onClick={onReset}>
          Reset Filters
        </Button>
      </Box>

      <Grid container spacing={3}>
        {/* Price Range */}
        <Grid item xs={12}>
          <Typography gutterBottom>Price Range ($/month)</Typography>
          <Slider
            value={[filters.minPrice || 0, filters.maxPrice || 5000]}
            onChange={handlePriceRangeChange}
            valueLabelDisplay="auto"
            min={0}
            max={5000}
            step={100}
          />
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
            <TextField
              label="Min Price"
              type="number"
              size="small"
              value={filters.minPrice || ''}
              onChange={(e) => handleChange('minPrice', e.target.value)}
              InputProps={{ startAdornment: '$' }}
            />
            <TextField
              label="Max Price"
              type="number"
              size="small"
              value={filters.maxPrice || ''}
              onChange={(e) => handleChange('maxPrice', e.target.value)}
              InputProps={{ startAdornment: '$' }}
            />
          </Box>
        </Grid>

        {/* Bedrooms */}
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth size="small">
            <InputLabel>Bedrooms</InputLabel>
            <Select
              value={filters.bedrooms || ''}
              label="Bedrooms"
              onChange={(e) => handleChange('bedrooms', e.target.value)}
            >
              <MenuItem value="">Any</MenuItem>
              <MenuItem value={1}>1</MenuItem>
              <MenuItem value={2}>2</MenuItem>
              <MenuItem value={3}>3</MenuItem>
              <MenuItem value={4}>4+</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        {/* Bathrooms */}
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth size="small">
            <InputLabel>Bathrooms</InputLabel>
            <Select
              value={filters.bathrooms || ''}
              label="Bathrooms"
              onChange={(e) => handleChange('bathrooms', e.target.value)}
            >
              <MenuItem value="">Any</MenuItem>
              <MenuItem value={1}>1</MenuItem>
              <MenuItem value={1.5}>1.5</MenuItem>
              <MenuItem value={2}>2</MenuItem>
              <MenuItem value={2.5}>2.5</MenuItem>
              <MenuItem value={3}>3+</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        {/* Sort By */}
        <Grid item xs={12}>
          <FormControl fullWidth size="small">
            <InputLabel>Sort By</InputLabel>
            <Select
              value={filters.sortBy || 'newest'}
              label="Sort By"
              onChange={(e) => handleChange('sortBy', e.target.value)}
            >
              <MenuItem value="newest">Newest First</MenuItem>
              <MenuItem value="price_low">Price: Low to High</MenuItem>
              <MenuItem value="price_high">Price: High to Low</MenuItem>
              <MenuItem value="bedrooms">Most Bedrooms</MenuItem>
              <MenuItem value="bathrooms">Most Bathrooms</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        {/* Available From */}
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Available From"
            type="date"
            size="small"
            value={filters.availableFrom || ''}
            onChange={(e) => handleChange('availableFrom', e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>

        {/* Active Filters Display */}
        {Object.entries(filters).some(([key, value]) => value) && (
          <Grid item xs={12}>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
              {Object.entries(filters).map(([key, value]) => {
                if (!value) return null;
                let label = '';
                switch (key) {
                  case 'minPrice':
                    label = `Min $${value}`;
                    break;
                  case 'maxPrice':
                    label = `Max $${value}`;
                    break;
                  case 'bedrooms':
                    label = `${value} bed`;
                    break;
                  case 'bathrooms':
                    label = `${value} bath`;
                    break;
                  case 'sortBy':
                    label = `Sort: ${value.replace('_', ' ')}`;
                    break;
                  case 'availableFrom':
                    label = `Available: ${new Date(value).toLocaleDateString()}`;
                    break;
                  default:
                    return null;
                }
                return (
                  <Chip
                    key={key}
                    label={label}
                    onDelete={() => handleChange(key, '')}
                    size="small"
                  />
                );
              })}
            </Box>
          </Grid>
        )}
      </Grid>
    </Paper>
  );
}
