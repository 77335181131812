import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Container,
  Paper,
  Typography,
  TextField,
  Button,
  Grid,
  Box,
  Chip,
  Alert,
  CircularProgress,
  Autocomplete,
  LinearProgress,
} from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useAuth } from '../../contexts/AuthContext';
import { listingService } from '../../services/listingService';
import { supabase } from '../../utils/supabaseClient';
import { loadGoogleMapsApi } from '../../utils/googleMapsLoader';
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from 'use-places-autocomplete';

const validationSchema = Yup.object({
  title: Yup.string().required('Title is required'),
  description: Yup.string().required('Description is required'),
  price: Yup.number().required('Price is required').positive('Price must be positive'),
  address: Yup.string().required('Address is required'),
  city: Yup.string().required('City is required'),
  state: Yup.string().required('State is required'),
  country: Yup.string().required('Country is required'),
  zip_code: Yup.string().required('ZIP code is required'),
  bedrooms: Yup.number().required('Number of bedrooms is required').positive().integer(),
  bathrooms: Yup.number().required('Number of bathrooms is required').positive(),
  square_feet: Yup.number().required('Square footage is required').positive().integer(),
  available_from: Yup.date().required('Available date is required'),
  minimum_stay: Yup.number().positive().integer(),
  maximum_stay: Yup.number().positive().integer(),
  amenities: Yup.array().of(Yup.string()),
});

export default function ListingForm({ mode = 'create' }) {
  const navigate = useNavigate();
  const { id } = useParams();
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [mapsLoaded, setMapsLoaded] = useState(false);
  const [mapsError, setMapsError] = useState(null);

  // Load Google Maps API
  useEffect(() => {
    loadGoogleMapsApi()
      .then(() => {
        setMapsLoaded(true);
        setMapsError(null);
      })
      .catch((error) => {
        console.error('Error loading Google Maps:', error);
        setMapsError(error.message);
      });
  }, []);

  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      componentRestrictions: { country: ['mx', 'us'] },
      types: ['address'],
    },
    debounce: 300,
    enabled: mapsLoaded,
  });

  useEffect(() => {
    if (mode === 'edit' && id) {
      fetchListing();
    }
  }, [mode, id]);

  useEffect(() => {
    console.log('Places API Status:', {
      ready,
      status,
      error: mapsError,
      apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY?.slice(0, 8) + '...',
      hasGoogleObject: !!window.google,
    });
  }, [ready, status, mapsError]);

  const fetchListing = async () => {
    try {
      setLoading(true);
      const data = await listingService.getListing(id);
      if (data) {
        formik.setValues({
          ...data,
          available_from: data.available_from.split('T')[0],
        });
        setValue(data.address, false);
      }
    } catch (error) {
      setError('Failed to fetch listing: ' + error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleAddressSelect = async (address) => {
    setValue(address, false);
    clearSuggestions();

    try {
      const results = await getGeocode({ address });
      const { lat, lng } = await getLatLng(results[0]);
      const addressComponents = results[0].address_components;

      let streetNumber = '';
      let route = '';
      let city = '';
      let state = '';
      let country = '';
      let zipCode = '';

      addressComponents.forEach(component => {
        const types = component.types;
        if (types.includes('street_number')) {
          streetNumber = component.long_name;
        } else if (types.includes('route')) {
          route = component.long_name;
        } else if (types.includes('locality')) {
          city = component.long_name;
        } else if (types.includes('administrative_area_level_1')) {
          state = component.short_name;
        } else if (types.includes('country')) {
          country = component.long_name;
        } else if (types.includes('postal_code')) {
          zipCode = component.long_name;
        }
      });

      formik.setValues({
        ...formik.values,
        address: `${streetNumber} ${route}`.trim(),
        city,
        state,
        country,
        zip_code: zipCode,
        latitude: lat,
        longitude: lng,
      });
    } catch (error) {
      setError('Error selecting address: ' + error.message);
    }
  };

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    setSelectedFiles(files);
  };

  const uploadImages = async () => {
    const uploadedUrls = [];
    setUploadProgress(0);

    for (let i = 0; i < selectedFiles.length; i++) {
      const file = selectedFiles[i];
      const fileExt = file.name.split('.').pop();
      const fileName = `${Math.random()}.${fileExt}`;
      const filePath = `${user.id}/${fileName}`;

      try {
        const { error: uploadError, data } = await supabase.storage
          .from('listing-images')
          .upload(filePath, file);

        if (uploadError) throw uploadError;

        const { data: { publicUrl } } = supabase.storage
          .from('listing-images')
          .getPublicUrl(filePath);

        uploadedUrls.push(publicUrl);
        setUploadProgress(((i + 1) / selectedFiles.length) * 100);
      } catch (error) {
        throw new Error(`Error uploading image ${i + 1}: ${error.message}`);
      }
    }

    return uploadedUrls;
  };

  const formik = useFormik({
    initialValues: {
      title: '',
      description: '',
      price: '',
      address: '',
      city: '',
      state: '',
      country: '',
      zip_code: '',
      bedrooms: '',
      bathrooms: '',
      square_feet: '',
      available_from: '',
      minimum_stay: '',
      maximum_stay: '',
      amenities: [],
      images: [],
      latitude: null,
      longitude: null,
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        setLoading(true);
        setError('');

        let imageUrls = [];
        if (selectedFiles.length > 0) {
          imageUrls = await uploadImages();
        }

        const listingData = {
          ...values,
          images: [...values.images, ...imageUrls],
          owner_id: user.id,
          status: 'available',
        };

        if (mode === 'edit') {
          await listingService.updateListing(id, listingData);
        } else {
          await listingService.createListing(listingData);
        }

        navigate('/listings');
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    },
  });

  const amenityOptions = [
    'Furnished', 'Utilities Included', 'Washer/Dryer', 'Parking',
    'Air Conditioning', 'Heating', 'Internet', 'Cable TV',
    'Gym', 'Pool', 'Pet Friendly', 'Dishwasher',
    'Security System', 'Balcony', 'Storage', 'Elevator'
  ];

  // Show loading state while Google Maps is initializing
  if (!mapsLoaded || !ready) {
    return (
      <Container maxWidth="md">
        <Paper sx={{ p: 4, my: 4 }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', py: 4 }}>
            <CircularProgress />
            <Typography sx={{ mt: 2 }}>
              {mapsError ? 'Error loading Google Maps' : 'Loading address search...'}
            </Typography>
            {mapsError && (
              <Alert severity="error" sx={{ mt: 2 }}>
                {mapsError}
              </Alert>
            )}
          </Box>
        </Paper>
      </Container>
    );
  }

  if (loading && mode === 'edit') {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', py: 8 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container maxWidth="md" sx={{ py: 4 }}>
      <Paper elevation={3} sx={{ p: 4 }}>
        <Typography variant="h4" gutterBottom>
          {mode === 'edit' ? 'Edit Listing' : 'Create New Listing'}
        </Typography>

        {error && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}

        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Title"
                name="title"
                value={formik.values.title}
                onChange={formik.handleChange}
                error={formik.touched.title && Boolean(formik.errors.title)}
                helperText={formik.touched.title && formik.errors.title}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Search Address"
                value={value}
                onChange={(e) => setValue(e.target.value)}
                disabled={!ready}
                error={formik.touched.address && Boolean(formik.errors.address)}
                helperText={formik.touched.address && formik.errors.address}
              />
              {status === "OK" && (
                <Box sx={{ 
                  position: 'absolute', 
                  zIndex: 1000, 
                  backgroundColor: 'white', 
                  width: '100%', 
                  maxHeight: '200px', 
                  overflowY: 'auto',
                  boxShadow: 3,
                  borderRadius: 1
                }}>
                  {data.map(suggestion => {
                    const {
                      place_id,
                      structured_formatting: { main_text, secondary_text },
                    } = suggestion;

                    return (
                      <Box
                        key={place_id}
                        sx={{
                          p: 1,
                          cursor: 'pointer',
                          '&:hover': {
                            backgroundColor: 'action.hover',
                          },
                        }}
                        onClick={() => handleAddressSelect(suggestion.description)}
                      >
                        <Typography variant="body1">{main_text}</Typography>
                        <Typography variant="body2" color="text.secondary">
                          {secondary_text}
                        </Typography>
                      </Box>
                    );
                  })}
                </Box>
              )}
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="City"
                name="city"
                value={formik.values.city}
                onChange={formik.handleChange}
                error={formik.touched.city && Boolean(formik.errors.city)}
                helperText={formik.touched.city && formik.errors.city}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="State/Province"
                name="state"
                value={formik.values.state}
                onChange={formik.handleChange}
                error={formik.touched.state && Boolean(formik.errors.state)}
                helperText={formik.touched.state && formik.errors.state}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Country"
                name="country"
                value={formik.values.country}
                onChange={formik.handleChange}
                error={formik.touched.country && Boolean(formik.errors.country)}
                helperText={formik.touched.country && formik.errors.country}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="ZIP/Postal Code"
                name="zip_code"
                value={formik.values.zip_code}
                onChange={formik.handleChange}
                error={formik.touched.zip_code && Boolean(formik.errors.zip_code)}
                helperText={formik.touched.zip_code && formik.errors.zip_code}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                rows={4}
                label="Description"
                name="description"
                value={formik.values.description}
                onChange={formik.handleChange}
                error={formik.touched.description && Boolean(formik.errors.description)}
                helperText={formik.touched.description && formik.errors.description}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                type="number"
                label="Price per month"
                name="price"
                value={formik.values.price}
                onChange={formik.handleChange}
                error={formik.touched.price && Boolean(formik.errors.price)}
                helperText={formik.touched.price && formik.errors.price}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                type="date"
                label="Available From"
                name="available_from"
                value={formik.values.available_from}
                onChange={formik.handleChange}
                InputLabelProps={{ shrink: true }}
                error={formik.touched.available_from && Boolean(formik.errors.available_from)}
                helperText={formik.touched.available_from && formik.errors.available_from}
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                type="number"
                label="Bedrooms"
                name="bedrooms"
                value={formik.values.bedrooms}
                onChange={formik.handleChange}
                error={formik.touched.bedrooms && Boolean(formik.errors.bedrooms)}
                helperText={formik.touched.bedrooms && formik.errors.bedrooms}
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                type="number"
                label="Bathrooms"
                name="bathrooms"
                value={formik.values.bathrooms}
                onChange={formik.handleChange}
                error={formik.touched.bathrooms && Boolean(formik.errors.bathrooms)}
                helperText={formik.touched.bathrooms && formik.errors.bathrooms}
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                type="number"
                label="Square Feet"
                name="square_feet"
                value={formik.values.square_feet}
                onChange={formik.handleChange}
                error={formik.touched.square_feet && Boolean(formik.errors.square_feet)}
                helperText={formik.touched.square_feet && formik.errors.square_feet}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                type="number"
                label="Minimum Stay (months)"
                name="minimum_stay"
                value={formik.values.minimum_stay}
                onChange={formik.handleChange}
                error={formik.touched.minimum_stay && Boolean(formik.errors.minimum_stay)}
                helperText={formik.touched.minimum_stay && formik.errors.minimum_stay}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                type="number"
                label="Maximum Stay (months)"
                name="maximum_stay"
                value={formik.values.maximum_stay}
                onChange={formik.handleChange}
                error={formik.touched.maximum_stay && Boolean(formik.errors.maximum_stay)}
                helperText={formik.touched.maximum_stay && formik.errors.maximum_stay}
              />
            </Grid>

            <Grid item xs={12}>
              <Autocomplete
                multiple
                options={amenityOptions}
                value={formik.values.amenities}
                onChange={(event, newValue) => {
                  formik.setFieldValue('amenities', newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Amenities"
                    placeholder="Select amenities"
                  />
                )}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      label={option}
                      {...getTagProps({ index })}
                      key={option}
                    />
                  ))
                }
              />
            </Grid>

            <Grid item xs={12}>
              <input
                accept="image/*"
                style={{ display: 'none' }}
                id="raised-button-file"
                multiple
                type="file"
                onChange={handleFileChange}
              />
              <label htmlFor="raised-button-file">
                <Button variant="contained" component="span">
                  Upload Images
                </Button>
              </label>
              {selectedFiles.length > 0 && (
                <Typography variant="body2" sx={{ mt: 1 }}>
                  {selectedFiles.length} files selected
                </Typography>
              )}
              {uploadProgress > 0 && (
                <Box sx={{ width: '100%', mt: 1 }}>
                  <LinearProgress variant="determinate" value={uploadProgress} />
                </Box>
              )}
            </Grid>

            <Grid item xs={12}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                size="large"
                disabled={loading}
                fullWidth
              >
                {loading ? (
                  <CircularProgress size={24} />
                ) : mode === 'edit' ? (
                  'Update Listing'
                ) : (
                  'Create Listing'
                )}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Container>
  );
}
