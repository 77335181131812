import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { supabase } from '../../utils/supabaseClient';
import {
  Container,
  Paper,
  Typography,
  Box,
  Grid,
  Avatar,
  IconButton,
  Divider,
  Chip,
} from '@mui/material';
import { LocationOn, Person } from '@mui/icons-material';
import Comments from '../comments/Comments';
import UserProfileModal from '../profile/UserProfileModal';
import ImageGallery from 'react-image-gallery';
import PropertyMap from '../maps/PropertyMap';
import 'react-image-gallery/styles/css/image-gallery.css';

const ListingDetails = () => {
  const { id } = useParams();
  const [listing, setListing] = useState(null);
  const [owner, setOwner] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    fetchListing();
  }, [id]);

  const fetchListing = async () => {
    try {
      const { data: listing, error: listingError } = await supabase
        .from('listings')
        .select(`
          *,
          profiles:owner_id (
            id,
            username,
            full_name,
            avatar_url,
            bio
          )
        `)
        .eq('id', id)
        .single();

      if (listingError) throw listingError;

      setListing(listing);
      setOwner(listing.profiles);
    } catch (error) {
      console.error('Error fetching listing:', error);
      setError('Failed to load listing details');
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <Typography>Loading...</Typography>;
  if (error) return <Typography color="error">{error}</Typography>;
  if (!listing) return <Typography>Listing not found</Typography>;

  const images = (listing.images || [])
    .filter(url => url) // Filter out null/undefined values
    .map(url => ({
      original: url,
      thumbnail: url,
    }));

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Paper elevation={3} sx={{ p: 3, mb: 4 }}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={8}>
            {images.length > 0 && (
              <Box sx={{ mb: 3 }}>
                <ImageGallery
                  items={images}
                  showPlayButton={false}
                  showFullscreenButton={true}
                  showNav={images.length > 1}
                  showThumbnails={images.length > 1}
                />
              </Box>
            )}

            <Typography variant="h4" gutterBottom>
              {listing.title}
            </Typography>

            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
              <LocationOn color="action" sx={{ mr: 1 }} />
              <Typography color="text.secondary">
                {listing.location}
              </Typography>
            </Box>

            <Typography variant="h6" color="primary" gutterBottom>
              ${listing.price}/month
            </Typography>

            <Box sx={{ my: 2 }}>
              <Typography variant="h6" gutterBottom>
                Description
              </Typography>
              <Typography paragraph>
                {listing.description}
              </Typography>
            </Box>

            {listing.latitude && listing.longitude && (
              <Box sx={{ my: 3 }}>
                <Typography variant="h6" gutterBottom>
                  Location
                </Typography>
                <PropertyMap
                  latitude={listing.latitude}
                  longitude={listing.longitude}
                  address={`${listing.address}, ${listing.city}, ${listing.state} ${listing.zip_code}`}
                />
              </Box>
            )}

            {listing.amenities && listing.amenities.length > 0 && (
              <Box sx={{ mb: 2 }}>
                <Typography variant="h6" gutterBottom>
                  Amenities
                </Typography>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                  {listing.amenities.map((amenity, index) => (
                    <Chip key={index} label={amenity} />
                  ))}
                </Box>
              </Box>
            )}
          </Grid>

          <Grid item xs={12} md={4}>
            <Paper elevation={2} sx={{ p: 2, mb: 3 }}>
              <Typography variant="h6" gutterBottom>
                Listed by
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <IconButton
                  onClick={() => setIsModalOpen(true)}
                  sx={{ p: 0, mr: 2 }}
                >
                  <Avatar
                    src={owner?.avatar_url}
                    alt={owner?.username}
                    sx={{ width: 56, height: 56 }}
                  >
                    <Person />
                  </Avatar>
                </IconButton>
                <Box>
                  <Typography variant="subtitle1">
                    {owner?.full_name || owner?.username}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    @{owner?.username}
                  </Typography>
                </Box>
              </Box>
            </Paper>

            <Paper elevation={2} sx={{ p: 2 }}>
              <Typography variant="h6" gutterBottom>
                Property Details
              </Typography>
              <Grid container spacing={2}>
                {listing.available_from && (
                  <Grid item xs={6}>
                    <Typography variant="body2" color="text.secondary">
                      Available from
                    </Typography>
                    <Typography>
                      {new Date(listing.available_from).toLocaleDateString()}
                    </Typography>
                  </Grid>
                )}
                {listing.room_type && (
                  <Grid item xs={6}>
                    <Typography variant="body2" color="text.secondary">
                      Room type
                    </Typography>
                    <Typography>
                      {listing.room_type}
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </Paper>
          </Grid>
        </Grid>

        <Divider sx={{ my: 4 }} />
        
        <Comments listingId={id} />
      </Paper>

      <UserProfileModal
        user={owner}
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    </Container>
  );
};

export default ListingDetails;
