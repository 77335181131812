import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import { ChatProvider } from './contexts/ChatContext';
import SignIn from './components/auth/SignIn';
import SignUp from './components/auth/SignUp';
import ProtectedRoute from './components/auth/ProtectedRoute';
import Layout from './components/layout/Layout';
import Profile from './components/profile/Profile';
import ListingsGrid from './components/listings/ListingsGrid';
import ListingForm from './components/listings/ListingForm';
import ListingDetails from './components/listings/ListingDetails';
import { ChatRoutes } from './components/chat';
import { ThemeProvider } from '@mui/material';
import LandingPage from './components/home/LandingPage';
import theme from './theme';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <AuthProvider>
          <ChatProvider>
            <Routes>
              {/* Public routes */}
              <Route path="/" element={<LandingPage />} />
              <Route path="/signin" element={<SignIn />} />
              <Route path="/signup" element={<SignUp />} />
              
              {/* Protected routes */}
              <Route
                path="/dashboard"
                element={
                  <ProtectedRoute>
                    <Layout>
                      <ListingsGrid />
                    </Layout>
                  </ProtectedRoute>
                }
              />
              <Route
                path="/profile"
                element={
                  <ProtectedRoute>
                    <Layout>
                      <Profile />
                    </Layout>
                  </ProtectedRoute>
                }
              />
              <Route
                path="/listings"
                element={
                  <ProtectedRoute>
                    <Layout>
                      <ListingsGrid />
                    </Layout>
                  </ProtectedRoute>
                }
              />
              <Route
                path="/listings/new"
                element={
                  <ProtectedRoute>
                    <Layout>
                      <ListingForm />
                    </Layout>
                  </ProtectedRoute>
                }
              />
              <Route
                path="/listings/:id"
                element={
                  <Layout>
                    <ListingDetails />
                  </Layout>
                }
              />
              <Route
                path="/listings/:id/edit"
                element={
                  <ProtectedRoute>
                    <Layout>
                      <ListingForm mode="edit" />
                    </Layout>
                  </ProtectedRoute>
                }
              />
              <Route
                path="/messages/*"
                element={
                  <ProtectedRoute>
                    <Layout>
                      <ChatRoutes />
                    </Layout>
                  </ProtectedRoute>
                }
              />

              {/* Redirect root to dashboard */}
              {/* <Route path="/" element={<Navigate to="/dashboard" replace />} /> */}
            </Routes>
          </ChatProvider>
        </AuthProvider>
      </Router>
    </ThemeProvider>
  );
}

export default App;
