import { supabase } from '../utils/supabaseClient';

export const savedSearchService = {
  async getSavedSearches() {
    const { data, error } = await supabase
      .from('saved_searches')
      .select('*')
      .order('created_at', { ascending: false });

    if (error) {
      throw new Error('Error fetching saved searches: ' + error.message);
    }

    return data;
  },

  async createSavedSearch(name, filters) {
    const { data, error } = await supabase
      .from('saved_searches')
      .insert([{ name, filters }])
      .select()
      .single();

    if (error) {
      throw new Error('Error creating saved search: ' + error.message);
    }

    return data;
  },

  async updateSavedSearch(id, name, filters) {
    const { data, error } = await supabase
      .from('saved_searches')
      .update({ name, filters })
      .eq('id', id)
      .select()
      .single();

    if (error) {
      throw new Error('Error updating saved search: ' + error.message);
    }

    return data;
  },

  async deleteSavedSearch(id) {
    const { error } = await supabase
      .from('saved_searches')
      .delete()
      .eq('id', id);

    if (error) {
      throw new Error('Error deleting saved search: ' + error.message);
    }
  },

  async getFavorites() {
    const { data, error } = await supabase
      .from('favorites')
      .select(`
        *,
        listing:listings (
          *,
          owner:profiles(id, full_name, avatar_url)
        )
      `)
      .order('created_at', { ascending: false });

    if (error) {
      throw new Error('Error fetching favorites: ' + error.message);
    }

    return data;
  },

  async addFavorite(listingId) {
    const { data, error } = await supabase
      .from('favorites')
      .insert([{ listing_id: listingId }])
      .select()
      .single();

    if (error) {
      throw new Error('Error adding favorite: ' + error.message);
    }

    return data;
  },

  async removeFavorite(listingId) {
    const { error } = await supabase
      .from('favorites')
      .delete()
      .eq('listing_id', listingId);

    if (error) {
      throw new Error('Error removing favorite: ' + error.message);
    }
  },

  async isFavorite(listingId) {
    const { data, error } = await supabase
      .from('favorites')
      .select('id')
      .eq('listing_id', listingId)
      .maybeSingle();

    if (error) {
      throw new Error('Error checking favorite status: ' + error.message);
    }

    return !!data;
  },
};
