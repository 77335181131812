import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Grid,
  Container,
  Typography,
  Button,
  Box,
  TextField,
  InputAdornment,
  CircularProgress,
  Alert,
  Collapse,
  IconButton,
} from '@mui/material';
import {
  Add as AddIcon,
  Search as SearchIcon,
  FilterList as FilterIcon,
  Bookmark as BookmarkIcon,
} from '@mui/icons-material';
import ListingCard from './ListingCard';
import ListingFilters from './ListingFilters';
import SavedSearchDialog from './SavedSearchDialog';
import SavedSearches from './SavedSearches';
import { listingService } from '../../services/listingService';

export default function ListingsGrid() {
  const navigate = useNavigate();
  const [listings, setListings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [showFilters, setShowFilters] = useState(false);
  const [filters, setFilters] = useState({
    minPrice: '',
    maxPrice: '',
    bedrooms: '',
    bathrooms: '',
    sortBy: 'newest',
    availableFrom: '',
  });
  const [showSavedSearches, setShowSavedSearches] = useState(false);
  const [saveSearchDialogOpen, setSaveSearchDialogOpen] = useState(false);

  useEffect(() => {
    fetchListings();
  }, []);

  const fetchListings = async () => {
    try {
      setLoading(true);
      const data = await listingService.getListings();
      setListings(data);
    } catch (error) {
      setError('Failed to fetch listings: ' + error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleFilterChange = (newFilters) => {
    setFilters(newFilters);
  };

  const resetFilters = () => {
    setFilters({
      minPrice: '',
      maxPrice: '',
      bedrooms: '',
      bathrooms: '',
      sortBy: 'newest',
      availableFrom: '',
    });
  };

  const filterListings = (listings) => {
    return listings.filter((listing) => {
      // Text search
      const matchesSearch = !searchTerm || 
        listing.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        listing.city.toLowerCase().includes(searchTerm.toLowerCase()) ||
        listing.state.toLowerCase().includes(searchTerm.toLowerCase());

      // Price range
      const matchesMinPrice = !filters.minPrice || listing.price >= filters.minPrice;
      const matchesMaxPrice = !filters.maxPrice || listing.price <= filters.maxPrice;

      // Bedrooms and bathrooms
      const matchesBedrooms = !filters.bedrooms || listing.bedrooms >= filters.bedrooms;
      const matchesBathrooms = !filters.bathrooms || listing.bathrooms >= filters.bathrooms;

      // Available date
      const matchesAvailableDate = !filters.availableFrom || 
        new Date(listing.available_from) <= new Date(filters.availableFrom);

      return matchesSearch && matchesMinPrice && matchesMaxPrice && 
             matchesBedrooms && matchesBathrooms && matchesAvailableDate;
    });
  };

  const sortListings = (listings) => {
    return [...listings].sort((a, b) => {
      switch (filters.sortBy) {
        case 'price_low':
          return a.price - b.price;
        case 'price_high':
          return b.price - a.price;
        case 'bedrooms':
          return b.bedrooms - a.bedrooms;
        case 'bathrooms':
          return b.bathrooms - a.bathrooms;
        case 'newest':
        default:
          return new Date(b.created_at) - new Date(a.created_at);
      }
    });
  };

  const filteredAndSortedListings = sortListings(filterListings(listings));

  const handleSaveSearch = (savedSearch) => {
    // Handle successful save
    console.log('Search saved:', savedSearch);
  };

  const handleApplySavedSearch = (filters) => {
    setFilters(filters);
    setShowSavedSearches(false);
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '60vh',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 4 }}>
        <Typography variant="h4" component="h1">
          Available Listings
        </Typography>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => navigate('/listings/new')}
        >
          Create Listing
        </Button>
      </Box>

      <Box sx={{ mb: 3 }}>
        <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Search by title, city, or state..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          <Button
            variant="outlined"
            startIcon={<FilterIcon />}
            onClick={() => setShowFilters(!showFilters)}
          >
            Filters
          </Button>
          <Button
            variant="outlined"
            startIcon={<BookmarkIcon />}
            onClick={() => setShowSavedSearches(!showSavedSearches)}
          >
            Saved
          </Button>
        </Box>

        <Collapse in={showFilters}>
          <Box sx={{ display: 'flex', gap: 2, alignItems: 'flex-start' }}>
            <Box sx={{ flex: 1 }}>
              <ListingFilters
                filters={filters}
                onFilterChange={handleFilterChange}
                onReset={resetFilters}
              />
            </Box>
            <Button
              variant="outlined"
              startIcon={<BookmarkIcon />}
              onClick={() => setSaveSearchDialogOpen(true)}
            >
              Save Search
            </Button>
          </Box>
        </Collapse>

        <Collapse in={showSavedSearches}>
          <SavedSearches onApplySearch={handleApplySavedSearch} />
        </Collapse>
      </Box>

      {error && (
        <Alert severity="error" sx={{ mb: 4 }}>
          {error}
        </Alert>
      )}

      {filteredAndSortedListings.length === 0 ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 2,
            py: 8,
          }}
        >
          <Typography variant="h6" color="text.secondary">
            No listings found
          </Typography>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => navigate('/listings/new')}
          >
            Create Your First Listing
          </Button>
        </Box>
      ) : (
        <Grid container spacing={3}>
          {filteredAndSortedListings.map((listing) => (
            <Grid item xs={12} sm={6} md={4} key={listing.id}>
              <ListingCard listing={listing} />
            </Grid>
          ))}
        </Grid>
      )}
      <SavedSearchDialog
        open={saveSearchDialogOpen}
        onClose={() => setSaveSearchDialogOpen(false)}
        filters={filters}
        onSave={handleSaveSearch}
      />
    </Container>
  );
}
