import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  Typography,
  Badge,
  Box,
  Divider,
  CircularProgress,
  Paper,
} from '@mui/material';
import { formatDistanceToNow } from 'date-fns';
import { chatService } from '../../services/chatService';
import { useAuth } from '../../contexts/AuthContext';
import { supabase } from '../../utils/supabaseClient';

export default function Conversations() {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [conversations, setConversations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (user) {
      fetchConversations();
      subscribeToNewMessages();
    }
  }, [user]);

  const fetchConversations = async () => {
    try {
      setError(null);
      const data = await chatService.getConversations();
      console.log('Fetched conversations:', data);
      setConversations(data);
    } catch (error) {
      console.error('Error fetching conversations:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const subscribeToNewMessages = () => {
    // Subscribe to all conversations for real-time updates
    const channel = supabase
      .channel('new_messages')
      .on(
        'postgres_changes',
        {
          event: 'INSERT',
          schema: 'public',
          table: 'messages',
        },
        (payload) => {
          console.log('New message received:', payload);
          // Update the conversations list when a new message arrives
          setConversations((prevConversations) => {
            const updatedConversations = [...prevConversations];
            const conversationIndex = updatedConversations.findIndex(
              (c) => c.id === payload.new.conversation_id
            );

            if (conversationIndex !== -1) {
              const conversation = { ...updatedConversations[conversationIndex] };
              conversation.lastMessage = payload.new;
              if (payload.new.sender_id !== user?.id) {
                conversation.unreadCount = (conversation.unreadCount || 0) + 1;
              }
              updatedConversations.splice(conversationIndex, 1);
              updatedConversations.unshift(conversation);
            } else {
              // If the conversation isn't in the list, fetch all conversations
              fetchConversations();
            }

            return updatedConversations;
          });
        }
      )
      .subscribe();

    return () => {
      channel.unsubscribe();
    };
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Typography color="error" sx={{ p: 3 }}>
        Error: {error}
      </Typography>
    );
  }

  return (
    <Paper elevation={0}>
      <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
        {conversations.length === 0 ? (
          <ListItem>
            <ListItemText
              primary="No conversations yet"
              secondary="Start chatting with property owners or potential roommates"
            />
          </ListItem>
        ) : (
          conversations.map((conversation) => {
            const otherUser = conversation.otherUser;
            return (
              <React.Fragment key={conversation.id}>
                <ListItem
                  button
                  onClick={() => navigate(`/messages/${conversation.id}`)}
                  sx={{
                    '&:hover': {
                      backgroundColor: 'action.hover',
                    },
                  }}
                >
                  <ListItemAvatar>
                    <Badge
                      color="error"
                      badgeContent={conversation.unreadCount}
                      invisible={!conversation.unreadCount}
                    >
                      <Avatar
                        src={otherUser?.avatar_url}
                        alt={otherUser?.full_name || otherUser?.username}
                      />
                    </Badge>
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <Typography
                        component="span"
                        variant="subtitle1"
                        color="text.primary"
                      >
                        {otherUser?.full_name || otherUser?.username}
                      </Typography>
                    }
                    secondary={
                      <React.Fragment>
                        {conversation.listing && (
                          <Typography
                            component="span"
                            variant="body2"
                            color="text.primary"
                          >
                            {conversation.listing.title} -{' '}
                          </Typography>
                        )}
                        {conversation.lastMessage ? (
                          <span>
                            {conversation.lastMessage.sender_id === user?.id
                              ? 'You: '
                              : ''}
                            {conversation.lastMessage.content}
                          </span>
                        ) : (
                          'No messages yet'
                        )}
                      </React.Fragment>
                    }
                  />
                  {conversation.lastMessage && (
                    <Typography
                      variant="caption"
                      color="text.secondary"
                      sx={{ ml: 2 }}
                    >
                      {formatDistanceToNow(
                        new Date(conversation.lastMessage.created_at),
                        { addSuffix: true }
                      )}
                    </Typography>
                  )}
                </ListItem>
                <Divider variant="inset" component="li" />
              </React.Fragment>
            );
          })
        )}
      </List>
    </Paper>
  );
}
