import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Box,
  Chip,
  Avatar,
  IconButton,
  CardActionArea,
  CardActions,
} from '@mui/material';
import {
  Favorite as FavoriteIcon,
  FavoriteBorder as FavoriteBorderIcon,
  BedOutlined,
  BathtubOutlined,
  SquareFootOutlined,
  Share,
} from '@mui/icons-material';
import { savedSearchService } from '../../services/savedSearchService';

export default function ListingCard({ listing }) {
  const navigate = useNavigate();
  const [isFavorite, setIsFavorite] = useState(false);

  useEffect(() => {
    checkFavoriteStatus();
  }, [listing.id]);

  const checkFavoriteStatus = async () => {
    try {
      const status = await savedSearchService.isFavorite(listing.id);
      setIsFavorite(status);
    } catch (error) {
      console.error('Error checking favorite status:', error);
    }
  };

  const handleFavoriteClick = async (event) => {
    event.stopPropagation();
    try {
      if (isFavorite) {
        await savedSearchService.removeFavorite(listing.id);
      } else {
        await savedSearchService.addFavorite(listing.id);
      }
      setIsFavorite(!isFavorite);
    } catch (error) {
      console.error('Error updating favorite:', error);
    }
  };

  const handleClick = () => {
    navigate(`/listings/${listing.id}`);
  };

  return (
    <Card sx={{ maxWidth: 345, height: '100%', display: 'flex', flexDirection: 'column' }}>
      <CardActionArea onClick={handleClick}>
        <CardMedia
          component="img"
          height="200"
          image={listing.images?.[0] || '/placeholder-house.jpg'}
          alt={listing.title}
        />
        <Box
          sx={{
            position: 'absolute',
            top: 8,
            right: 8,
            display: 'flex',
            gap: 1,
          }}
        >
          <IconButton
            onClick={handleFavoriteClick}
            sx={{
              bgcolor: 'background.paper',
              '&:hover': { bgcolor: 'background.paper' },
            }}
          >
            {isFavorite ? (
              <FavoriteIcon color="error" />
            ) : (
              <FavoriteBorderIcon />
            )}
          </IconButton>
        </Box>
        <CardContent>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', mb: 1 }}>
            <Typography variant="h6" component="div" sx={{ fontWeight: 'bold' }}>
              ${listing.price.toLocaleString()}/mo
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Avatar
                src={listing.owner?.avatar_url}
                alt={listing.owner?.full_name}
                sx={{ width: 24, height: 24, mr: 1 }}
              />
              <Typography variant="body2" color="text.secondary">
                {listing.owner?.username}
              </Typography>
            </Box>
          </Box>

          <Typography gutterBottom variant="subtitle1" component="div">
            {listing.title}
          </Typography>

          <Typography variant="body2" color="text.secondary" gutterBottom>
            {listing.city}, {listing.state}
          </Typography>

          <Box sx={{ display: 'flex', gap: 2, mt: 2 }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <BedOutlined sx={{ mr: 0.5 }} />
              <Typography variant="body2">{listing.bedrooms} bd</Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <BathtubOutlined sx={{ mr: 0.5 }} />
              <Typography variant="body2">{listing.bathrooms} ba</Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <SquareFootOutlined sx={{ mr: 0.5 }} />
              <Typography variant="body2">{listing.square_feet.toLocaleString()} sqft</Typography>
            </Box>
          </Box>
        </CardContent>
      </CardActionArea>
      
      <CardActions sx={{ mt: 'auto' }}>
        <IconButton aria-label="share" size="small">
          <Share />
        </IconButton>
        <Box sx={{ ml: 'auto' }}>
          <Chip
            label="Available"
            color="success"
            size="small"
            variant="outlined"
          />
        </Box>
      </CardActions>
    </Card>
  );
}
