import React, { useState, useEffect } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { supabase } from '../../utils/supabaseClient';
import {
  Box,
  TextField,
  Button,
  Typography,
  Avatar,
  IconButton,
  Paper,
  Divider,
  Stack,
} from '@mui/material';
import { Delete as DeleteIcon } from '@mui/icons-material';
import UserProfileModal from '../profile/UserProfileModal';

const Comments = ({ listingId }) => {
  const { user } = useAuth();
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [selectedUser, setSelectedUser] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    fetchComments();
    // Subscribe to realtime comments
    const commentsSubscription = supabase
      .channel('comments')
      .on(
        'postgres_changes',
        {
          event: '*',
          schema: 'public',
          table: 'comments',
          filter: `listing_id=eq.${listingId}`,
        },
        () => {
          fetchComments();
        }
      )
      .subscribe();

    return () => {
      commentsSubscription.unsubscribe();
    };
  }, [listingId]);

  const fetchComments = async () => {
    try {
      const { data, error } = await supabase
        .rpc('get_comments_with_user_info', { listing_id_param: listingId });

      if (error) throw error;
      setComments(data);
    } catch (error) {
      console.error('Error fetching comments:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!newComment.trim()) return;

    try {
      setLoading(true);
      setError('');

      const { error } = await supabase
        .from('comments')
        .insert({
          listing_id: listingId,
          user_id: user.id,
          content: newComment.trim(),
        });

      if (error) throw error;
      setNewComment('');
    } catch (error) {
      console.error('Error posting comment:', error);
      setError('Failed to post comment. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (commentId) => {
    try {
      const { error } = await supabase
        .from('comments')
        .delete()
        .eq('id', commentId);

      if (error) throw error;
    } catch (error) {
      console.error('Error deleting comment:', error);
      setError('Failed to delete comment. Please try again.');
    }
  };

  const handleUserClick = (userData) => {
    setSelectedUser(userData);
    setIsModalOpen(true);
  };

  return (
    <Box sx={{ mt: 4 }}>
      <Typography variant="h6" gutterBottom>
        Comments
      </Typography>

      {user && (
        <Paper sx={{ p: 2, mb: 2 }}>
          <form onSubmit={handleSubmit}>
            <TextField
              fullWidth
              multiline
              rows={2}
              placeholder="Write a comment..."
              value={newComment}
              onChange={(e) => setNewComment(e.target.value)}
              disabled={loading}
              error={!!error}
              helperText={error}
              sx={{ mb: 1 }}
            />
            <Button
              type="submit"
              variant="contained"
              disabled={loading || !newComment.trim()}
            >
              Post Comment
            </Button>
          </form>
        </Paper>
      )}

      <Stack spacing={2}>
        {comments.map((comment) => (
          <Paper key={comment.id} sx={{ p: 2 }}>
            <Box sx={{ display: 'flex', alignItems: 'flex-start', mb: 1 }}>
              <IconButton
                onClick={() => handleUserClick({
                  id: comment.user_id,
                  username: comment.username,
                  full_name: comment.full_name,
                  avatar_url: comment.avatar_url,
                })}
                sx={{ p: 0, mr: 1 }}
              >
                <Avatar src={comment.avatar_url} alt={comment.username} />
              </IconButton>
              <Box sx={{ flexGrow: 1 }}>
                <Typography variant="subtitle2">
                  {comment.full_name || comment.username}
                </Typography>
                <Typography variant="caption" color="text.secondary">
                  {new Date(comment.created_at).toLocaleString()}
                </Typography>
                <Typography sx={{ mt: 1 }}>{comment.content}</Typography>
              </Box>
              {(user?.id === comment.user_id || user?.user_metadata?.role === 'admin') && (
                <IconButton
                  size="small"
                  onClick={() => handleDelete(comment.id)}
                  sx={{ ml: 1 }}
                >
                  <DeleteIcon />
                </IconButton>
              )}
            </Box>
          </Paper>
        ))}
      </Stack>

      <UserProfileModal
        user={selectedUser}
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    </Box>
  );
};

export default Comments;
