import { supabase } from '../utils/supabaseClient';

export const chatService = {
  async getConversations() {
    const { data: userProfile } = await supabase.auth.getUser();
    if (!userProfile?.user?.id) throw new Error('User not authenticated');

    // Get all conversations where user is a participant
    const { data: participations, error: participationsError } = await supabase
      .from('conversation_participants')
      .select(`
        conversation_id,
        other_user_id,
        other_user:profiles!conversation_participants_other_user_id_fkey (
          id,
          username,
          full_name,
          avatar_url
        )
      `)
      .eq('user_id', userProfile.user.id);

    if (participationsError) throw participationsError;
    if (!participations?.length) return [];

    // Get full conversation data
    const { data: conversations, error: conversationsError } = await supabase
      .from('conversations')
      .select(`
        *,
        listing:listing_id (
          id,
          title,
          images
        )
      `)
      .in('id', participations.map(p => p.conversation_id));

    if (conversationsError) throw conversationsError;

    // Get messages for these conversations
    const { data: messages, error: messagesError } = await supabase
      .from('messages')
      .select(`
        *,
        sender:profiles!messages_sender_id_fkey (
          id,
          username,
          avatar_url
        )
      `)
      .in('conversation_id', participations.map(p => p.conversation_id))
      .order('created_at', { ascending: false });

    if (messagesError) throw messagesError;

    // Combine all the data
    return conversations.map(conversation => {
      const participation = participations.find(p => p.conversation_id === conversation.id);
      const conversationMessages = messages.filter(m => m.conversation_id === conversation.id);
      const lastMessage = conversationMessages[0];
      const unreadCount = conversationMessages.filter(
        m => !m.is_read && m.sender_id !== userProfile.user.id
      ).length;

      return {
        ...conversation,
        messages: conversationMessages,
        otherUser: participation.other_user,
        lastMessage,
        unreadCount,
      };
    });
  },

  async startConversation(otherUserId) {
    const { data: userProfile } = await supabase.auth.getUser();
    if (!userProfile?.user?.id) throw new Error('User not authenticated');

    // Check if conversation already exists
    const { data: existingConvo, error: searchError } = await supabase
      .from('conversation_participants')
      .select('conversation_id')
      .eq('user_id', userProfile.user.id)
      .eq('other_user_id', otherUserId)
      .single();

    if (searchError && searchError.code !== 'PGRST116') throw searchError;
    if (existingConvo) return existingConvo.conversation_id;

    // Create new conversation
    const { data: conversation, error: conversationError } = await supabase
      .from('conversations')
      .insert({})
      .select()
      .single();

    if (conversationError) throw conversationError;

    // Add participants
    const { error: participantsError } = await supabase
      .from('conversation_participants')
      .insert([
        {
          conversation_id: conversation.id,
          user_id: userProfile.user.id,
          other_user_id: otherUserId
        },
        {
          conversation_id: conversation.id,
          user_id: otherUserId,
          other_user_id: userProfile.user.id
        }
      ]);

    if (participantsError) throw participantsError;

    return conversation.id;
  },

  async getMessages(conversationId) {
    const { data: userProfile } = await supabase.auth.getUser();
    if (!userProfile?.user?.id) throw new Error('User not authenticated');

    // Verify user is a participant
    const { data: participant } = await supabase
      .from('conversation_participants')
      .select()
      .eq('conversation_id', conversationId)
      .eq('user_id', userProfile.user.id)
      .single();

    if (!participant) throw new Error('Not authorized to view this conversation');

    const { data: messages, error } = await supabase
      .from('messages')
      .select(`
        *,
        sender:profiles!messages_sender_id_fkey (
          id,
          username,
          avatar_url
        )
      `)
      .eq('conversation_id', conversationId)
      .order('created_at', { ascending: true });

    if (error) throw error;
    return messages;
  },

  async getConversation(conversationId) {
    const { data: userProfile } = await supabase.auth.getUser();
    if (!userProfile?.user?.id) throw new Error('User not authenticated');

    // Get conversation with participant info
    const { data: conversation, error: conversationError } = await supabase
      .from('conversations')
      .select(`
        *,
        conversation_participants!inner (
          user_id,
          other_user_id,
          other_user:profiles!conversation_participants_other_user_id_fkey (
            id,
            username,
            full_name,
            avatar_url
          )
        )
      `)
      .eq('id', conversationId)
      .single();

    if (conversationError) throw conversationError;

    // Get the participant info for the current user
    const participant = conversation.conversation_participants.find(
      p => p.user_id === userProfile.user.id
    );

    if (!participant) throw new Error('Not authorized to view this conversation');

    // Get the other user's info
    const otherUser = participant.other_user;

    return {
      ...conversation,
      otherUser
    };
  },

  async sendMessage(conversationId, content) {
    const { data: userProfile } = await supabase.auth.getUser();
    if (!userProfile?.user?.id) throw new Error('User not authenticated');

    const { data, error } = await supabase
      .from('messages')
      .insert({
        conversation_id: conversationId,
        sender_id: userProfile.user.id,
        content
      })
      .select()
      .single();

    if (error) throw error;
    return data;
  },

  async markMessagesAsRead(conversationId) {
    const { data: userProfile } = await supabase.auth.getUser();
    if (!userProfile?.user?.id) throw new Error('User not authenticated');

    const { error } = await supabase
      .from('messages')
      .update({ is_read: true })
      .eq('conversation_id', conversationId)
      .neq('sender_id', userProfile.user.id);

    if (error) throw error;
  },

  subscribeToMessages(conversationId, onMessage) {
    return supabase
      .channel(`messages:${conversationId}`)
      .on(
        'postgres_changes',
        {
          event: 'INSERT',
          schema: 'public',
          table: 'messages',
          filter: `conversation_id=eq.${conversationId}`
        },
        onMessage
      )
      .subscribe();
  },

  subscribeToConversation(conversationId, onUpdate) {
    return supabase
      .channel(`conversation:${conversationId}`)
      .on(
        'postgres_changes',
        {
          event: '*',
          schema: 'public',
          table: 'conversations',
          filter: `id=eq.${conversationId}`
        },
        onUpdate
      )
      .subscribe();
  }
};
