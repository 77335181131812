import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Conversations from './Conversations';
import ChatRoom from './ChatRoom';
import { Box, Paper } from '@mui/material';

export default function ChatRoutes() {
  return (
    <Box sx={{ maxWidth: 800, mx: 'auto', p: 2 }}>
      <Paper elevation={3}>
        <Routes>
          <Route path="/" element={<Conversations />} />
          <Route path="/:id" element={<ChatRoom />} />
          <Route path="*" element={<Navigate to="/messages" replace />} />
        </Routes>
      </Paper>
    </Box>
  );
}
