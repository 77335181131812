import React from 'react';
import { Box } from '@mui/material';

const DefaultListingCard = () => {
  return (
    <Box
      sx={{
        width: '100%',
        height: '240px',
        background: 'linear-gradient(135deg, #3bedb7 0%, #2c3e50 100%)',
        borderRadius: '16px 16px 0 0',
        position: 'relative',
        overflow: 'hidden',
        '&::after': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background: 'linear-gradient(45deg, rgba(255,255,255,0.1) 0%, rgba(255,255,255,0) 100%)',
        },
      }}
    />
  );
};

export default DefaultListingCard;
