import React, { useState, useEffect } from 'react';
import {
  Box,
  Container,
  Typography,
  Button,
  Grid,
  Card,
  CardMedia,
  CardContent,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  useTheme,
  useMediaQuery,
  IconButton,
  Avatar,
  Chip,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { LocationOn, ArrowForward, Close as CloseIcon } from '@mui/icons-material';
import { supabase } from '../../utils/supabaseClient';
import { motion } from 'framer-motion';
import DefaultHero from './DefaultHero';
import DefaultListingCard from './DefaultListingCard';

const MotionCard = motion(Card);
const MotionBox = motion(Box);

export default function LandingPage() {
  const [latestListings, setLatestListings] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    fetchLatestListings();
  }, []);

  const fetchLatestListings = async () => {
    try {
      const { data, error } = await supabase
        .from('listings')
        .select(`
          *,
          profiles:owner_id (
            username,
            avatar_url
          )
        `)
        .order('created_at', { ascending: false })
        .limit(3);

      if (error) throw error;
      setLatestListings(data);
    } catch (error) {
      console.error('Error fetching listings:', error);
    }
  };

  const handleMoreClick = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleSignUp = () => {
    navigate('/signup');
  };

  return (
    <Box sx={{ bgcolor: 'background.default' }}>
      {/* Hero Section */}
      <Box
        sx={{
          bgcolor: 'background.paper',
          pt: { xs: 8, sm: 12 },
          pb: { xs: 8, sm: 12 },
          position: 'relative',
          overflow: 'hidden',
        }}
      >
        <Container maxWidth="lg">
          <Grid container spacing={4} alignItems="center">
            <Grid item xs={12} md={6}>
              <MotionBox
                initial={{ opacity: 0, x: -50 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.5 }}
              >
                <Typography
                  component="h1"
                  variant="h1"
                  sx={{
                    color: 'secondary.main',
                    mb: 2,
                    fontWeight: 800,
                  }}
                >
                  Find Your Perfect
                  <Box component="span" sx={{ color: 'primary.main', display: 'block' }}>
                    Home Together
                  </Box>
                </Typography>
                <Typography
                  variant="h5"
                  sx={{
                    color: 'text.secondary',
                    mb: 4,
                    lineHeight: 1.6,
                  }}
                >
                  Mi Casa connects you with like-minded roommates and amazing properties.
                  Start your journey to shared living today.
                </Typography>
                <Button
                  variant="contained"
                  size="large"
                  onClick={handleSignUp}
                  sx={{
                    mr: 2,
                    px: 4,
                    py: 1.5,
                  }}
                >
                  Get Started
                </Button>
              </MotionBox>
            </Grid>
            <Grid item xs={12} md={6}>
              <MotionBox
                initial={{ opacity: 0, x: 50 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.5, delay: 0.2 }}
                sx={{
                  position: 'relative',
                  height: { xs: 300, md: 500 },
                }}
              >
                <DefaultHero />
              </MotionBox>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Latest Listings Section */}
      <Container maxWidth="lg" sx={{ py: { xs: 6, sm: 8 } }}>
        <Box sx={{ mb: 6 }}>
          <Typography variant="h3" sx={{ mb: 2 }}>
            Latest Properties
          </Typography>
          <Typography variant="subtitle1" color="text.secondary">
            Discover our newest shared living spaces
          </Typography>
        </Box>
        <Grid container spacing={4}>
          {latestListings.map((listing, index) => (
            <Grid item xs={12} md={4} key={listing.id}>
              <MotionCard
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
                sx={{ height: '100%' }}
              >
                <CardMedia
                  component={() => <DefaultListingCard />}
                  height="240"
                />
                <CardContent>
                  <Box sx={{ mb: 2 }}>
                    <Typography variant="h5" gutterBottom>
                      {listing.title}
                    </Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                      <LocationOn sx={{ color: 'primary.main', mr: 1 }} />
                      <Typography variant="body2" color="text.secondary">
                        {listing.location}
                      </Typography>
                    </Box>
                    <Typography variant="h6" color="primary.main">
                      ${listing.price}/month
                    </Typography>
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Avatar
                        src={listing.profiles?.avatar_url}
                        alt={listing.profiles?.username}
                        sx={{ width: 32, height: 32, mr: 1 }}
                      />
                      <Typography variant="body2" color="text.secondary">
                        {listing.profiles?.username}
                      </Typography>
                    </Box>
                    <Chip
                      label={listing.room_type}
                      size="small"
                      sx={{ bgcolor: 'primary.light', color: 'primary.dark' }}
                    />
                  </Box>
                </CardContent>
              </MotionCard>
            </Grid>
          ))}
        </Grid>
        <Box sx={{ textAlign: 'center', mt: 6 }}>
          <Button
            variant="outlined"
            size="large"
            onClick={handleMoreClick}
            endIcon={<ArrowForward />}
          >
            View More Properties
          </Button>
        </Box>
      </Container>

      {/* Sign Up Dialog */}
      <Dialog
        open={openDialog}
        onClose={handleClose}
        maxWidth="sm"
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: 3,
            p: 2,
          },
        }}
      >
        <DialogTitle>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Typography variant="h4">Join Mi Casa</Typography>
            <IconButton onClick={handleClose} size="small">
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1" sx={{ mb: 2 }}>
            Create an account to view more properties, connect with roommates, and find your perfect home.
          </Typography>
        </DialogContent>
        <DialogActions sx={{ p: 3 }}>
          <Button onClick={handleClose} variant="outlined" sx={{ mr: 2 }}>
            Maybe Later
          </Button>
          <Button onClick={handleSignUp} variant="contained" autoFocus>
            Sign Up Now
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
