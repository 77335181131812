import React, { createContext, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../utils/supabaseClient';
import { useAuth } from './AuthContext';

const ChatContext = createContext();

export const useChatContext = () => {
  const context = useContext(ChatContext);
  if (!context) {
    throw new Error('useChatContext must be used within a ChatProvider');
  }
  return context;
};

export const ChatProvider = ({ children }) => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const startConversation = async (otherUserId) => {
    if (!user) {
      navigate('/login');
      return null;
    }

    try {
      setLoading(true);
      console.log('Starting conversation between', user.id, 'and', otherUserId);

      // Check if conversation already exists
      const { data: existingConvo, error: searchError } = await supabase
        .from('conversation_participants')
        .select('conversation_id')
        .eq('user_id', user.id)
        .eq('other_user_id', otherUserId)
        .single();

      if (searchError && searchError.code !== 'PGRST116') {
        console.error('Error checking existing conversation:', searchError);
        throw searchError;
      }

      let conversationId;

      if (existingConvo) {
        console.log('Found existing conversation:', existingConvo.conversation_id);
        conversationId = existingConvo.conversation_id;
      } else {
        console.log('Creating new conversation');
        // Create new conversation
        const { data: newConvo, error: createError } = await supabase
          .from('conversations')
          .insert({})
          .select()
          .single();

        if (createError) {
          console.error('Error creating conversation:', createError);
          throw createError;
        }

        console.log('Created conversation:', newConvo.id);

        // Add participants
        const { error: participantsError } = await supabase
          .from('conversation_participants')
          .insert([
            {
              conversation_id: newConvo.id,
              user_id: user.id,
              other_user_id: otherUserId
            },
            {
              conversation_id: newConvo.id,
              user_id: otherUserId,
              other_user_id: user.id
            }
          ]);

        if (participantsError) {
          console.error('Error adding participants:', participantsError);
          throw participantsError;
        }

        console.log('Added participants successfully');
        conversationId = newConvo.id;
      }

      return conversationId;
    } catch (error) {
      console.error('Error in startConversation:', error);
      throw new Error(error.message || 'Failed to start conversation');
    } finally {
      setLoading(false);
    }
  };

  return (
    <ChatContext.Provider
      value={{
        startConversation,
        loading
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};
