import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Avatar,
  Typography,
  Box,
  IconButton,
  Divider,
  CircularProgress,
  Alert,
} from '@mui/material';
import { Message as MessageIcon, Close as CloseIcon } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { useChatContext } from '../../contexts/ChatContext';

const UserProfileModal = ({ user, open, onClose }) => {
  const { user: currentUser } = useAuth();
  const { startConversation, loading } = useChatContext();
  const navigate = useNavigate();
  const [error, setError] = useState(null);

  if (!user) return null;

  const handleMessage = async () => {
    if (!currentUser) {
      navigate('/login');
      return;
    }

    try {
      setError(null);
      console.log('Starting conversation with user:', user.id);
      const conversationId = await startConversation(user.id);
      console.log('Conversation created:', conversationId);
      
      if (conversationId) {
        navigate(`/messages/${conversationId}`);
        onClose();
      } else {
        setError('Failed to create conversation. Please try again.');
      }
    } catch (error) {
      console.error('Error starting conversation:', error);
      setError(error.message || 'Failed to start conversation');
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6">User Profile</Typography>
          <IconButton onClick={onClose} size="small">
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box display="flex" flexDirection="column" alignItems="center" p={2}>
          <Avatar
            src={user.avatar_url}
            alt={user.username}
            sx={{ width: 120, height: 120, mb: 2 }}
          />
          <Typography variant="h6" gutterBottom>
            {user.full_name}
          </Typography>
          <Typography variant="subtitle1" color="text.secondary" gutterBottom>
            @{user.username}
          </Typography>
          {user.bio && (
            <Typography variant="body1" align="center" sx={{ mt: 2, mb: 3 }}>
              {user.bio}
            </Typography>
          )}
          
          {currentUser?.id !== user.id && (
            <>
              <Divider sx={{ width: '100%', my: 2 }} />
              {error && (
                <Alert severity="error" sx={{ mb: 2, width: '100%' }}>
                  {error}
                </Alert>
              )}
              <Button
                startIcon={loading ? <CircularProgress size={20} /> : <MessageIcon />}
                variant="contained"
                onClick={handleMessage}
                disabled={loading}
                size="large"
                fullWidth
                sx={{ mt: 1 }}
              >
                {loading ? 'Opening Chat...' : 'Send Message'}
              </Button>
            </>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default UserProfileModal;
