import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Box,
} from '@mui/material';
import { savedSearchService } from '../../services/savedSearchService';

export default function SavedSearchDialog({ open, onClose, filters, onSave }) {
  const [name, setName] = useState('');
  const [error, setError] = useState('');

  const handleSave = async () => {
    try {
      if (!name.trim()) {
        setError('Please enter a name for your saved search');
        return;
      }

      const savedSearch = await savedSearchService.createSavedSearch(name, filters);
      onSave(savedSearch);
      setName('');
      setError('');
      onClose();
    } catch (error) {
      setError(error.message);
    }
  };

  const handleClose = () => {
    setName('');
    setError('');
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>Save Search</DialogTitle>
      <DialogContent>
        <Box sx={{ pt: 1 }}>
          <TextField
            autoFocus
            fullWidth
            label="Search Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            error={!!error}
            helperText={error}
            placeholder="e.g., 2 Bed Downtown"
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleSave} variant="contained">
          Save Search
        </Button>
      </DialogActions>
    </Dialog>
  );
}
