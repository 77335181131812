import React, { useState, useEffect } from 'react';
import {
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Typography,
  Paper,
  Box,
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
} from '@mui/material';
import {
  MoreVert as MoreVertIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
} from '@mui/icons-material';
import { savedSearchService } from '../../services/savedSearchService';

export default function SavedSearches({ onApplySearch }) {
  const [savedSearches, setSavedSearches] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [menuAnchor, setMenuAnchor] = useState(null);
  const [selectedSearch, setSelectedSearch] = useState(null);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [editName, setEditName] = useState('');

  useEffect(() => {
    fetchSavedSearches();
  }, []);

  const fetchSavedSearches = async () => {
    try {
      setLoading(true);
      const data = await savedSearchService.getSavedSearches();
      setSavedSearches(data);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleMenuOpen = (event, search) => {
    setMenuAnchor(event.currentTarget);
    setSelectedSearch(search);
  };

  const handleMenuClose = () => {
    setMenuAnchor(null);
    setSelectedSearch(null);
  };

  const handleEdit = () => {
    setEditName(selectedSearch.name);
    setEditDialogOpen(true);
    handleMenuClose();
  };

  const handleEditSave = async () => {
    try {
      await savedSearchService.updateSavedSearch(
        selectedSearch.id,
        editName,
        selectedSearch.filters
      );
      fetchSavedSearches();
      setEditDialogOpen(false);
    } catch (error) {
      setError(error.message);
    }
  };

  const handleDelete = async () => {
    try {
      await savedSearchService.deleteSavedSearch(selectedSearch.id);
      fetchSavedSearches();
      handleMenuClose();
    } catch (error) {
      setError(error.message);
    }
  };

  const handleApply = (search) => {
    onApplySearch(search.filters);
  };

  const formatFilters = (filters) => {
    const parts = [];
    if (filters.minPrice) parts.push(`$${filters.minPrice}+`);
    if (filters.maxPrice) parts.push(`Up to $${filters.maxPrice}`);
    if (filters.bedrooms) parts.push(`${filters.bedrooms}+ beds`);
    if (filters.bathrooms) parts.push(`${filters.bathrooms}+ baths`);
    if (filters.availableFrom) {
      parts.push(`Available from ${new Date(filters.availableFrom).toLocaleDateString()}`);
    }
    return parts.join(' · ') || 'No filters applied';
  };

  if (loading) {
    return <Typography>Loading saved searches...</Typography>;
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  return (
    <Paper elevation={0}>
      <Box sx={{ p: 2 }}>
        <Typography variant="h6" gutterBottom>
          Saved Searches
        </Typography>
        {savedSearches.length === 0 ? (
          <Typography color="text.secondary">
            No saved searches yet. Save your current search to quickly access it later.
          </Typography>
        ) : (
          <List>
            {savedSearches.map((search) => (
              <ListItem
                key={search.id}
                button
                onClick={() => handleApply(search)}
                sx={{ borderRadius: 1, mb: 1 }}
              >
                <ListItemText
                  primary={search.name}
                  secondary={formatFilters(search.filters)}
                />
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    onClick={(event) => handleMenuOpen(event, search)}
                  >
                    <MoreVertIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        )}
      </Box>

      <Menu
        anchorEl={menuAnchor}
        open={Boolean(menuAnchor)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={handleEdit}>
          <EditIcon fontSize="small" sx={{ mr: 1 }} />
          Edit Name
        </MenuItem>
        <MenuItem onClick={handleDelete}>
          <DeleteIcon fontSize="small" sx={{ mr: 1 }} />
          Delete
        </MenuItem>
      </Menu>

      <Dialog
        open={editDialogOpen}
        onClose={() => setEditDialogOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Edit Saved Search</DialogTitle>
        <DialogContent>
          <Box sx={{ pt: 1 }}>
            <TextField
              autoFocus
              fullWidth
              label="Search Name"
              value={editName}
              onChange={(e) => setEditName(e.target.value)}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleEditSave} variant="contained">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
}
