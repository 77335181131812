import { supabase } from '../utils/supabaseClient';

export const listingService = {
  async createListing(listingData) {
    const { data: userProfile } = await supabase.auth.getUser();
    if (!userProfile?.user?.id) throw new Error('User not authenticated');

    // First ensure profile exists
    const { data: profile, error: profileError } = await supabase
      .from('profiles')
      .select()
      .eq('id', userProfile.user.id)
      .single();

    if (profileError) {
      console.error('Error fetching profile:', profileError);
      throw new Error('Please complete your profile before creating a listing');
    }

    // Then create the listing
    const { data, error } = await supabase
      .from('listings')
      .insert([{
        ...listingData,
        owner_id: userProfile.user.id,
      }])
      .select(`
        *,
        owner:profiles (
          id,
          full_name,
          avatar_url
        )
      `)
      .single();

    if (error) {
      console.error('Error creating listing:', error);
      throw error;
    }
    return data;
  },

  async updateListing(id, updates) {
    const { data: userProfile } = await supabase.auth.getUser();
    if (!userProfile?.user?.id) throw new Error('User not authenticated');

    const { data, error } = await supabase
      .from('listings')
      .update(updates)
      .eq('id', id)
      .eq('owner_id', userProfile.user.id)
      .select(`
        *,
        owner:profiles (
          id,
          full_name,
          avatar_url
        )
      `)
      .single();

    if (error) throw error;
    return data;
  },

  async deleteListing(id) {
    const { data: userProfile } = await supabase.auth.getUser();
    if (!userProfile?.user?.id) throw new Error('User not authenticated');

    const { error } = await supabase
      .from('listings')
      .delete()
      .eq('id', id)
      .eq('owner_id', userProfile.user.id);

    if (error) throw error;
  },

  async getListing(id) {
    const { data, error } = await supabase
      .from('listings')
      .select(`
        *,
        owner:profiles (
          id,
          full_name,
          avatar_url
        )
      `)
      .eq('id', id)
      .single();

    if (error) {
      throw new Error('Error fetching listing: ' + error.message);
    }

    return data;
  },

  async getListings(filters = {}) {
    let query = supabase
      .from('listings')
      .select(`
        *,
        owner:profiles (
          id,
          full_name,
          avatar_url
        )
      `);

    // Apply filters
    if (filters.minPrice) {
      query = query.gte('price', filters.minPrice);
    }
    if (filters.maxPrice) {
      query = query.lte('price', filters.maxPrice);
    }
    if (filters.bedrooms) {
      query = query.eq('bedrooms', filters.bedrooms);
    }
    if (filters.bathrooms) {
      query = query.eq('bathrooms', filters.bathrooms);
    }
    if (filters.city) {
      query = query.ilike('city', `%${filters.city}%`);
    }
    if (filters.state) {
      query = query.ilike('state', `%${filters.state}%`);
    }
    if (filters.availableFrom) {
      query = query.gte('available_from', filters.availableFrom);
    }
    if (filters.status) {
      query = query.eq('status', filters.status);
    }

    const { data, error } = await query;

    if (error) {
      throw new Error('Error fetching listings: ' + error.message);
    }

    return data;
  },

  async getMyListings() {
    const { data: userProfile } = await supabase.auth.getUser();
    if (!userProfile?.user?.id) throw new Error('User not authenticated');

    const { data, error } = await supabase
      .from('listings')
      .select(`
        *,
        owner:profiles (
          id,
          full_name,
          avatar_url
        )
      `)
      .eq('owner_id', userProfile.user.id)
      .order('created_at', { ascending: false });

    if (error) throw error;
    return data;
  },

  async uploadListingImages(files) {
    const { data: userProfile } = await supabase.auth.getUser();
    if (!userProfile?.user?.id) throw new Error('User not authenticated');

    const uploadPromises = files.map(async (file) => {
      const fileExt = file.name.split('.').pop();
      const fileName = `${Math.random().toString(36).substring(2)}.${fileExt}`;
      const filePath = `${userProfile.user.id}/${fileName}`;

      const { error: uploadError } = await supabase.storage
        .from('listings')
        .upload(filePath, file);

      if (uploadError) throw uploadError;

      const { data: { publicUrl } } = supabase.storage
        .from('listings')
        .getPublicUrl(filePath);

      return publicUrl;
    });

    return Promise.all(uploadPromises);
  },
};
