import React, { useState, useCallback, memo } from 'react';
import { GoogleMap, useLoadScript, Marker, StreetViewPanorama } from '@react-google-maps/api';
import { Box, Button, ButtonGroup, CircularProgress } from '@mui/material';
import { Map as MapIcon, Streetview as StreetViewIcon } from '@mui/icons-material';

const mapContainerStyle = {
  width: '100%',
  height: '400px',
  borderRadius: '8px',
};

const options = {
  mapTypeControl: false,
  streetViewControl: false,
  fullscreenControl: true,
  zoomControl: true,
};

const PropertyMap = memo(({ latitude, longitude, address }) => {
  const [showStreetView, setShowStreetView] = useState(false);
  const mapApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: mapApiKey,
    libraries: ['places'],
  });

  const center = {
    lat: parseFloat(latitude),
    lng: parseFloat(longitude),
  };

  const renderMap = useCallback(() => (
    <GoogleMap
      mapContainerStyle={mapContainerStyle}
      center={center}
      zoom={15}
      options={options}
    >
      {!showStreetView ? (
        <Marker position={center} title={address} />
      ) : (
        <StreetViewPanorama
          position={center}
          visible={true}
          options={{
            enableCloseButton: false,
            addressControl: false,
          }}
        />
      )}
    </GoogleMap>
  ), [center, showStreetView, address]);

  if (loadError) {
    return (
      <Box sx={{ 
        height: 400, 
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'center',
        bgcolor: 'grey.100',
        borderRadius: 1,
      }}>
        Error loading Google Maps
      </Box>
    );
  }

  return (
    <Box sx={{ position: 'relative', mt: 2 }}>
      <ButtonGroup 
        variant="contained" 
        sx={{ 
          position: 'absolute', 
          top: 10, 
          right: 10, 
          zIndex: 1,
          backgroundColor: 'white',
          boxShadow: 2,
          borderRadius: 1,
        }}
      >
        <Button
          onClick={() => setShowStreetView(false)}
          variant={!showStreetView ? "contained" : "outlined"}
          startIcon={<MapIcon />}
        >
          Map
        </Button>
        <Button
          onClick={() => setShowStreetView(true)}
          variant={showStreetView ? "contained" : "outlined"}
          startIcon={<StreetViewIcon />}
        >
          Street View
        </Button>
      </ButtonGroup>

      {!isLoaded ? (
        <Box sx={{ 
          height: 400, 
          display: 'flex', 
          alignItems: 'center', 
          justifyContent: 'center',
          bgcolor: 'grey.100',
          borderRadius: 1,
        }}>
          <CircularProgress />
        </Box>
      ) : (
        renderMap()
      )}
    </Box>
  );
});

PropertyMap.displayName = 'PropertyMap';

export default PropertyMap;
