import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Box,
  Paper,
  Typography,
  TextField,
  IconButton,
  Avatar,
  Divider,
  CircularProgress,
  Button,
} from '@mui/material';
import {
  Send as SendIcon,
  ArrowBack as ArrowBackIcon,
  Home as HomeIcon,
} from '@mui/icons-material';
import { formatDistanceToNow } from 'date-fns';
import { chatService } from '../../services/chatService';
import { useAuth } from '../../contexts/AuthContext';

export default function ChatRoom() {
  const { id: conversationId } = useParams();
  const navigate = useNavigate();
  const { user } = useAuth();
  const [conversation, setConversation] = useState(null);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const messagesEndRef = useRef(null);
  const [sending, setSending] = useState(false);

  useEffect(() => {
    fetchConversation();
    fetchMessages();
    const subscription = subscribeToMessages();
    markMessagesAsRead();

    return () => {
      subscription.unsubscribe();
    };
  }, [conversationId]);

  const fetchConversation = async () => {
    try {
      const data = await chatService.getConversation(conversationId);
      setConversation(data);
    } catch (error) {
      setError('Error loading conversation: ' + error.message);
    }
  };

  const fetchMessages = async () => {
    try {
      const data = await chatService.getMessages(conversationId);
      setMessages(data);
      setLoading(false);
      scrollToBottom();
    } catch (error) {
      setError('Error loading messages: ' + error.message);
      setLoading(false);
    }
  };

  const subscribeToMessages = () => {
    return chatService.subscribeToMessages(conversationId, (message) => {
      setMessages((prev) => [...prev, message]);
      if (message.sender_id !== user.id) {
        markMessagesAsRead();
      }
      scrollToBottom();
    });
  };

  const markMessagesAsRead = async () => {
    try {
      await chatService.markMessagesAsRead(conversationId);
    } catch (error) {
      console.error('Error marking messages as read:', error);
    }
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const handleSend = async (e) => {
    e.preventDefault();
    if (!newMessage.trim() || sending) return;

    try {
      setSending(true);
      await chatService.sendMessage(conversationId, newMessage.trim());
      setNewMessage('');
    } catch (error) {
      setError('Error sending message: ' + error.message);
    } finally {
      setSending(false);
    }
  };

  const getOtherParticipant = () => {
    if (!conversation) return null;
    return conversation.conversation_participants.find(
      (p) => p.user_id !== user.id
    )?.profiles;
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ p: 3 }}>
        <Typography color="error" gutterBottom>
          {error}
        </Typography>
        <Button
          startIcon={<ArrowBackIcon />}
          onClick={() => navigate('/messages')}
        >
          Back to Conversations
        </Button>
      </Box>
    );
  }

  const otherParticipant = getOtherParticipant();

  return (
    <Paper
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: 'calc(100vh - 100px)',
      }}
    >
      {/* Header */}
      <Box
        sx={{
          p: 2,
          bgcolor: 'background.paper',
          borderBottom: 1,
          borderColor: 'divider',
          display: 'flex',
          alignItems: 'center',
          gap: 2,
        }}
      >
        <IconButton onClick={() => navigate('/messages')}>
          <ArrowBackIcon />
        </IconButton>
        <Avatar
          src={otherParticipant?.avatar_url}
          alt={otherParticipant?.full_name}
        />
        <Box sx={{ flex: 1 }}>
          <Typography variant="subtitle1">
            {otherParticipant?.full_name}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {conversation?.listings?.title}
          </Typography>
        </Box>
        <IconButton
          onClick={() => navigate(`/listings/${conversation?.listings?.id}`)}
        >
          <HomeIcon />
        </IconButton>
      </Box>

      {/* Messages */}
      <Box
        sx={{
          flex: 1,
          overflow: 'auto',
          p: 2,
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
        }}
      >
        {messages.map((message) => {
          const isOwnMessage = message.sender_id === user.id;
          return (
            <Box
              key={message.id}
              sx={{
                display: 'flex',
                justifyContent: isOwnMessage ? 'flex-end' : 'flex-start',
                mb: 1,
              }}
            >
              {!isOwnMessage && (
                <Avatar
                  src={otherParticipant?.avatar_url}
                  alt={otherParticipant?.full_name}
                  sx={{ width: 32, height: 32, mr: 1 }}
                />
              )}
              <Box
                sx={{
                  maxWidth: '70%',
                  bgcolor: isOwnMessage ? 'primary.main' : 'grey.100',
                  color: isOwnMessage ? 'white' : 'text.primary',
                  borderRadius: 2,
                  p: 1.5,
                  position: 'relative',
                }}
              >
                <Typography variant="body1">{message.content}</Typography>
                <Typography
                  variant="caption"
                  sx={{
                    color: isOwnMessage ? 'rgba(255,255,255,0.7)' : 'text.secondary',
                    display: 'block',
                    mt: 0.5,
                  }}
                >
                  {formatDistanceToNow(new Date(message.created_at), {
                    addSuffix: true,
                  })}
                </Typography>
              </Box>
            </Box>
          );
        })}
        <div ref={messagesEndRef} />
      </Box>

      {/* Message Input */}
      <Divider />
      <Box
        component="form"
        onSubmit={handleSend}
        sx={{
          p: 2,
          bgcolor: 'background.paper',
          display: 'flex',
          gap: 1,
        }}
      >
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Type a message..."
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          size="small"
          disabled={sending}
        />
        <IconButton
          color="primary"
          type="submit"
          disabled={!newMessage.trim() || sending}
        >
          <SendIcon />
        </IconButton>
      </Box>
    </Paper>
  );
}
